import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { detect } from 'detect-browser';
import tw from 'twin.macro';

import WideLogo from '../svgs/WideLogo';
import Loading from '../images/loading.png';
import SEO from '../components/seo';
import getEnvVars from '../environment';

const { appSchema } = getEnvVars();

const RESPOND_TO_ACTIVITY_CHECK = gql`
  mutation RESPOND_TO_ACTIVITY_CHECK($token: String!, $deactivate: Boolean!) {
    respondToActivityCheck(token: $token, deactivate: $deactivate)
  }
`;

const Section = tw.section`w-screen h-screen bg-gray-200 flex flex-col justify-center items-center text-center`;

const RedirectButton = () => {
  const browser = detect();

  if (browser.os === 'iOS' || browser.os === 'Android OS' || browser.os === 'android') {
    const handleClick = () => {
      setTimeout(
        () => {
          if (browser.os === 'iOS') {
            window.location = 'https://apps.apple.com/de/app/dropfriends/id1491312504?mt=8';
          } else if (browser.os === 'Android OS' || browser.os === 'android') {
            window.location =
              'https://play.google.com/store/apps/details?id=com.dropfriends.dropfriends';
          }
        },
        browser.os === 'Android OS' || browser.os === 'android' ? 1500 : 3000
      );
    };

    return (
      <a
        onClick={handleClick}
        href={appSchema}
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-8 mt-4 rounded-full focus:outline-none focus:shadow-outline">
        Zur DropFriends-App
      </a>
    );
  }

  return null;
};

const ActivityCheck = () => {
  const [status, setStatus] = useState('VERIFYING');
  const [respondToActivityCheck, { data }] = useMutation(RESPOND_TO_ACTIVITY_CHECK);

  const handleConfirmation = async (token, deactivate) => {
    await respondToActivityCheck({ variables: { token, deactivate } });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const deactivate = urlParams.get('deactivate');

    if (token) {
      handleConfirmation(token, deactivate === 'true');
    } else {
      setStatus('NO_TOKEN_PROVIDED');
    }
  }, []);

  useEffect(() => {
    if (data && data.respondToActivityCheck) {
      setStatus(data.respondToActivityCheck);
    }
  }, [data]);

  return (
    <>
      <SEO
        title="DropPoint Aktivitätscheck"
        meta={[
          {
            property: 'robots',
            content: 'noindex, nofollow',
          },
        ]}
      />
      <Section>
        <WideLogo className="mb-8" />
        {status === 'VERIFYING' ? (
          <>
            <p>Aktivitätscheck wird durchgeführt.</p>
            <div className="mt-4">
              <img src={Loading} className="h-12 spin-animation" alt="Loading Spinner" />
            </div>
          </>
        ) : null}
        {status === 'ALREADY_VERIFIED' ? (
          <>
            <p>Danke für Deine Rückmeldung. Du kannst dieses Fenster nun schließen.</p>
            <RedirectButton />
          </>
        ) : null}
        {status === 'NO_TOKEN_PROVIDED' || status === 'USER_NOT_FOUND' ? (
          <p>Dieser Link ist nicht mehr gültig oder Du hast ihn bereits benutzt.</p>
        ) : null}
        {status === 'SUCCESS' ? (
          <>
            <p>Danke für Deine Rückmeldung. Du kannst dieses Fenster nun schließen.</p>
            <RedirectButton />
          </>
        ) : null}
      </Section>
    </>
  );
};

export default ActivityCheck;
